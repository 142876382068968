window.DataAppConfig = {
  ENVIRONMENT: "#{environment}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  PORTAL_API_URL: "#{portalApiUrl}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  LINKS_URL: "#{linksUrl}#",
  PRODUCTS_API_URL: "#{productsApiUrl}#",
};
